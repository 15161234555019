import GSAP from 'gsap'

import Component from 'classes/Component'

import Hamburger from 'animations/Hamburger'

import { EVENT_ON_NAV_OPEN, EVENT_ON_NAV_CLOSE } from 'utils/events'
import { COLOR_WHITE, COLOR_DARK_GRAY } from 'utils/colors'
import { CUBIC_STRONG as ease } from 'utils/easings'

import { mapEach } from 'utils/dom'

export default class Navigation extends Component {
  constructor({ template, transition }) {
    super({
      element: '.navigation',
      elements: {
        logo: '.navigation__logo',
        menu: '.navigation__menu',
        hamburger: '.navigation__menu__wrapper',
        closeWrapper: '.navigation__close__wrapper',
        closeIcon: '.navigation__close__icon',
        overlay: '.navigation__overlay',
        title: '.navigation__list__title',
        items: '.navigation__list__item',
        links: '.navigation__list__link',
        lines: '.navigation__list__link__span__span',
        social: '.navigation__social__list__item',
        footer: '.navigation__footer',
      },
    })

    // this.links = mapEach(this.elements.links, (element) => {
    //   return new Link({
    //     element,
    //   })
    // })

    this.FIRST_TRANSITION_DELAY = template === 'home' ? 1 : 0.5

    this.navOpenFlag = false
    this.transition = transition
    this.timeline = GSAP.timeline()

    // this.show()
    this.createMenu()
    this.addListeners()
    // this.onChange(template)
  }

  createMenu() {
    this.hamburger = new Hamburger({
      element: this.elements.menu,
      elements: {
        hamburger: this.elements.hamburger,
        closeWrapper: this.elements.closeWrapper,
        closeIcon: this.elements.closeIcon,
      },
    })

    this.hamburger.on(EVENT_ON_NAV_OPEN, this.doOpen.bind(this))
    this.hamburger.on(EVENT_ON_NAV_CLOSE, this.doClose.bind(this))
  }

  animateSplits() {
    const lines = []

    GSAP.from(this.elements.lines, {
      duration: 0.8,
      opacity: 0,
      scale: 0,
      yPercent: 80,
      //rotationX: 180,
      //transformOrigin: "0% 50% -50",
      ease: 'back',
      stagger: 0.01,
    })

    // this.elements.links.forEach((element) => {
    //   split({ element })
    //   split({ element })

    //   lines.push(...element.querySelectorAll('span span'))
    // })
  }

  async show() {
    return new Promise((resolve) => {
      const timeline = GSAP.timeline()

      timeline
        .from(this.elements.logo, {
          duration: 1,
          xPercent: -150,
          ease,
        })

        .to(
          this.elements.logo,
          {
            duration: 1,
            autoAlpha: 1,
          },
          '-=.5',
        )

        .call(
          () => {
            resolve()
          },
          null,
          `-=${this.FIRST_TRANSITION_DELAY}`,
        )

        .call(
          () => {
            this.hamburger.show()
          },
          null,
          `+=1`,
        )
    })
  }

  onChange(index) {
    for (const link of this.elements.links) {
      link.classList.remove('navigation__list__link--active')
    }

    if (!index) return

    this.elements.links[index].classList.add('navigation__list__link--active')
  }

  doOpen() {
    this.navOpenFlag = true

    this.timeline.killTweensOf(this.elements.lines)
    this.timeline.killTweensOf(this.elements.items)
    this.timeline.killTweensOf(this.elements.title)
    this.timeline.killTweensOf(this.elements.social)
    this.timeline.killTweensOf(this.elements.footer)

    this.transition.show({
      color: COLOR_DARK_GRAY,
    })

    this.timeline
      .set(this.elements.overlay, {
        autoAlpha: 1,
        display: 'flex',
        transform: 'translateY(-100%)',
      })
      .set(this.elements.lines, {
        yPercent: -100,
      })
      .set(this.elements.items, {
        autoAlpha: 0,
      })
      .set(this.elements.title, {
        autoAlpha: 1,
      })
      .set(this.elements.social, {
        yPercent: 0,
      })
      .set(this.elements.social, {
        autoAlpha: 1,
      })
      .set(this.elements.footer, {
        autoAlpha: 1,
      })

      // Overlay
      .to(this.elements.overlay, {
        duration: 0.8,
        ease: 'expo.inOut',
        transform: 'translateY(0%)',

        callbackScope: this,
        onComplete: () => {
          this.hamburger.active = true
        },
      })

      // Logo
      .to(
        this.elements.logo,
        {
          duration: 0.15,
          color: COLOR_WHITE,
        },
        '-=.4',
      )

      // Nav items
      .to(
        this.elements.lines,
        {
          duration: 0.8,
          yPercent: 0,
          ease: 'back',
          stagger: 0.05,
        },
        '-=.6',
      )

      // Nav items
      .to(
        this.elements.items,
        {
          duration: 0.8,
          autoAlpha: 1,
          stagger: 0.05,
        },
        '-=.9',
      )

      // Title
      .from(
        this.elements.title,
        {
          duration: 0.8,
          xPercent: -150,
          ease,
        },
        '-=1.3',
      )
      .from(
        this.elements.title,
        {
          duration: 0.8,
          autoAlpha: 0,
        },
        '-=1.1',
      )

      // Social
      .from(
        this.elements.social,
        {
          duration: 0.8,
          xPercent: -50,
          stagger: 0.05,
          ease,
        },
        '-=1.3',
      )
      .from(
        this.elements.social,
        {
          duration: 0.8,
          autoAlpha: 0,
          stagger: 0.05,
        },
        '-=1.1',
      )

      // Footer
      .from(
        this.elements.footer,
        {
          duration: 0.8,
          autoAlpha: 0,
        },
        '-=1',
      )

    // this.transition.hide({
    //   color: COLOR_DARK_GRAY,
    // })
  }

  doClose() {
    this.timeline
      // Overlay
      .call(() => {
        this.transition.hide({
          color: COLOR_DARK_GRAY,
        })
      })

      .to(this.elements.overlay, {
        duration: 0.8,
        ease: 'expo.inOut',
        y: '-100%',
        callbackScope: this,
        onComplete: () => {
          GSAP.set(this.elements.overlay, {
            autoAlpha: 0,
            display: 'none',
          })

          this.hamburger.reset()
          this.navOpenFlag = false
        },
      })

      // Logo
      .to(
        this.elements.logo,
        {
          duration: 0.1,
          color: 'currentColor',
        },
        '-=.2',
      )

      // Nav items
      /*  .to(
        this.elements.lines,
        {
          duration: 0.8,
          yPercent: -100,
          ease,
          stagger: 0.05,
        },
        '-=.9',
      )*/

      // Nav items
      .to(
        this.elements.items,
        {
          duration: 0.8,
          autoAlpha: 0,
          stagger: 0.05,
        },
        '-=1.2',
      )

      // Title
      .to(
        this.elements.title,
        {
          duration: 0.8,
          autoAlpha: 0,
        },
        '-=1.2',
      )

      // Social
      .to(
        this.elements.social,
        {
          duration: 0.8,
          autoAlpha: 0,
          stagger: 0.05,
        },
        '-=1.2',
      )

      // Footer
      .to(
        this.elements.footer,
        {
          duration: 0.8,
          autoAlpha: 0,
        },
        '-=1',
      )
  }

  reset() {
    this.doClose()
    this.hamburger.changeToBurger()
  }

  addListeners() {
    // This case covers whenever user clicks on nav item that is already active
    this.elements.items.forEach((item) => {
      item.onclick = () => {
        this.reset()
      }
    })
  }
}
