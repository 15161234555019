import GSAP from 'gsap'

export function lerp(p1, p2, t) {
  //return GSAP.utils.interpolate(p1, p2, t)
  return p1 + (p2 - p1) * t
}

export function interpolate(start, end, value) {
  return start * (1.0 - value) + end * value
}

export function clamp(min, max, number) {
  // return GSAP.utils.clamp(min, max, number)
  return Math.max(min, Math.min(number, max))
}

export function random(min, max) {
  return GSAP.utils.random(min, max)
}

export function map(valueToMap, inMin, inMax, outMin, outMax) {
  return GSAP.utils.mapRange(inMin, inMax, outMin, outMax, valueToMap)
}
