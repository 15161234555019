import each from 'lodash/each'

import Animation from 'classes/Animation'

import { CSS } from 'utils/easings'
import { calculate, split } from 'utils/text'

export default class extends Animation {
  constructor({ element }) {
    const lines = []
    const paragraphs = element.querySelectorAll('h1, h2, p')

    if (paragraphs.length !== 0) {
      each(paragraphs, (element) => {
        split({ element })
        split({ element })

        lines.push(...element.querySelectorAll('span span'))
      })
    } else {
      split({ element })
      split({ element })

      lines.push(...element.querySelectorAll('span span'))
    }

    super({
      element,
      elements: {
        lines,
      },
    })

    this.initialAnimate = true
    this.onResize()

    if ('IntersectionObserver' in window) {
      this.animateOut()
    }
  }

  animateIn() {
    super.animateIn()
    each(this.lines, (line, lineIndex) => {
      each(line, (word) => {
        word.style.transition = `transform 1.5s ${lineIndex * 0.1}s ${CSS}`
        word.style[this.transformPrefix] = 'translateY(0)'
      })
    })

    this.initialAnimate = false
  }

  animateOut() {
    super.animateOut()
    each(this.lines, (line, lineIndex) => {
      each(line, (word) => {
        if (!this.initialAnimate) {
          word.style.transition = `transform 0.5s ${lineIndex * 0.07}s ${CSS}`
        }

        word.style[this.transformPrefix] = this.initialAnimate
          ? 'translateY(100%)'
          : 'translateY(-100%)'
      })
    })
  }

  onResize() {
    this.lines = calculate(this.elements.lines)
  }
}
