import GSAP from 'gsap'
import Page from 'classes/Page'
import { each, map } from 'lodash'
import { EVENT_ON_TOP_CLICK } from '../utils/events'
import { CUBIC_STRONG as ease } from 'utils/easings'

export default class extends Page {
  constructor() {
    super({
      id: 'home',

      classes: {
        active: 'home--active',
      },

      element: '.home',
      elements: {
        navigation: document.querySelector('.navigation'),
        wrapper: '.home__wrapper',
        items: '.home__project__link__box',
        links: '.home__project__link',
        button: '.home__top__button',
        tag: '.home__intro__tag',
        footer: '.home__footer',
      },
    })
  }

  create() {
    super.create()
    this.masks = map(this.elements.items, (mask) => {
      return mask.firstChild
    })
  }

  createMaskObserver() {
    this.observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Only animate masks that are in view
        if (entry.isIntersecting) {
          this.animateMasksIn(entry.target)
          this.observer.unobserve(entry.target)
        }
      })
    })

    this.masks.forEach((mask) => {
      this.observer.observe(mask)
    })
  }

  /**
   * Animations.
   */
  async show() {
    // super.show()
    this.element.style.opacity = 1

    this.element.classList.add(this.classes.active)

    GSAP.to(this.elements.tag, {
      opacity: 1,
      duration: 0.6,
      delay: 1,
    })

    // this.animateMasksIn()

    // return new Promise((resolve) => {
    this.animationIn = GSAP.timeline()

    this.animationIn.from(this.masks, {
      duration: 1,
      scaleY: 1,
      stagger: 0.1,
      ease,
      delay: 1,
    })

    //   this.animationIn.from(this.masks, {
    //     duration: 5,
    //     scaleY: 1,
    //     stagger: 0.1,
    //     ease,
    //     delay: 1,
    //   })

    //   this.animationIn.call((_) => {
    //     resolve()
    //   })
    // })

    /*if ('IntersectionObserver' in window) {
      this.animateMasksIn()
    } else {
      this.animateMasksIn()
    }*/

    return super.show(this.animationIn)
  }

  animateMasksIn(element = null) {
    GSAP.from(this.masks, {
      duration: 1,
      scaleY: 1,
      stagger: 0.1,
      ease,
      delay: 1,
    })
  }

  async hide() {
    this.destroy()
    this.element.classList.remove(this.classes.active)

    const timeline = GSAP.timeline()

    GSAP.to(this.elements.tag, {
      opacity: 0,
      duration: 0.6,
      delay: 0,
    })

    GSAP.to(this.elements.footer, {
      opacity: 0,
      duration: 0.6,
    })

    GSAP.to(this.elements.button, {
      opacity: 0,
      duration: 0.6,
    })

    timeline.to(this.masks, {
      duration: 1,
      scaleY: 1,
      ease,
    })

    GSAP.to(this.elements.links, {
      opacity: 0,
      duration: 0.6,
    })

    return super.hide(timeline)
  }

  /**
   * Events.
   */
  onResize() {
    super.onResize()
  }

  onMouseEnter({ target }) {
    const image = target.querySelector('img')
    //image.setAttribute('data-active', 1)

    this.currentScale = image.style.transform.scale

    // GSAP.to(image, {
    //   duration: 0.3,
    //   opacity: 0.8,
    // })

    GSAP.to(image, {
      webkitFilter: 'brightness(.5)',
      filter: 'brightness(.5)',
      duration: 0.3,
    })
  }
  onMouseLeave({ target }) {
    const image = target.querySelector('img')

    // GSAP.to(image, {
    //   duration: 0.3,
    //   opacity: 1,
    //   // ease: 'circ.out',
    //   onComplete: () => {
    //     // image.setAttribute('data-active', 0)
    //   },
    // })

    GSAP.to(image, {
      webkitFilter: 'brightness(1)',
      filter: 'brightness(1)',
      duration: 0.3,
    })
  }

  onTopClick() {
    this.emit(EVENT_ON_TOP_CLICK)
  }

  onProjectClick({ target }) {
    const link = target.querySelector('.home__project__link')
    const icon = target.querySelector('.home__project__link__icon')

    GSAP.killTweensOf(link)

    GSAP.to(icon, {
      duration: 1,
      xPercent: 100,
      ease,
    })
    GSAP.to(icon, {
      duration: 0.5,
      opacity: 0,
      delay: 0.1,
    })
    GSAP.to(link, {
      duration: 0.8,
      opacity: 1,
    })
  }

  /**
   * Loop.
   */
  update() {
    super.update()
  }

  /**
   * Listeners
   */
  addEventListeners() {
    this.onMouseEnterEvent = this.onMouseEnter.bind(this)
    this.onMouseLeaveEvent = this.onMouseLeave.bind(this)
    this.onTopClickEvent = this.onTopClick.bind(this)

    each(this.elements.items, (project) => {
      project.addEventListener('mouseenter', this.onMouseEnterEvent)
      project.addEventListener('mouseleave', this.onMouseLeaveEvent)
      project.addEventListener('click', this.onProjectClick)
    })

    this.elements.button.addEventListener('click', this.onTopClickEvent)
  }

  removeEventListeners() {
    each(this.elements.items, (project) => {
      project.removeEventListener('mouseenter', this.onMouseEnterEvent)
      project.removeEventListener('mouseleave', this.onMouseLeaveEvent)
      project.removeEventListener('click', this.onProjectClickEvent)
    })

    this.elements.button.removeEventListener('click', this.onTopClickEvent)
  }

  /**
   * Destroy.
   */
  destroy() {
    this.removeEventListeners()
  }
}
