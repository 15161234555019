import GSAP from 'gsap'
import Page from 'classes/Page'
import Detection from 'classes/Detection'
import { EVENT_ON_TOP_CLICK } from '../utils/events'
import { CUBIC_STRONG as ease } from 'utils/easings'

export default class extends Page {
  constructor() {
    super({
      id: 'project',

      classes: {
        active: 'project--active',
      },

      element: '.project',
      elements: {
        wrapper: '.project__wrapper',

        navigation: document.querySelector('.navigation'),
        title: '.project__text__title',
        stats: '.project__stats__item',
        button: '.project__button__top',
        prev: '.project__button__prev',
        next: '.project__button__next',
      },
    })
  }

  create() {
    super.create()
  }

  /**
   * Animations.
   */
  async show() {
    this.element.style.opacity = 1
    this.element.classList.add(this.classes.active)
    const timeline = GSAP.timeline()

    GSAP.set(this.elements.stats, {
      opacity: 0,
    })

    GSAP.from(this.elements.title, {
      duration: 0.5,
      opacity: 0,
    })

    GSAP.from(this.elements.stats, {
      duration: 0.8,
      xPercent: -100,
      stagger: 0.03,
      ease,
      delay: 0.3,
    })
    GSAP.to(this.elements.stats, {
      opacity: 1,
      duration: 0.8,
      stagger: 0.05,
      delay: 0.3,
    })

    timeline.set('html', {
      '--project--scaleX': '100%',
    })

    // Project Media Items
    timeline.to('html', {
      '--project--scaleX': '0%',
      duration: 1,
      scaleY: 1,
      stagger: 0.1,
      ease,
      delay: 0.5,
    })

    if (!Detection.isPhone()) {
      GSAP.set(this.elements.prev, {
        xPercent: -100,
        opacity: 0,
      })
      GSAP.set(this.elements.next, {
        xPercent: 100,
        opacity: 0,
      })
      timeline.to(this.elements.prev, {
        xPercent: 0,
        duration: 0.8,
        opacity: 1,
        ease,
      })
      timeline.to(
        this.elements.next,
        {
          xPercent: 0,
          duration: 0.8,
          opacity: 1,
          ease,
        },
        '-=0.8',
      )
    }

    return super.show(timeline)
  }

  async hide() {
    this.destroy()
    this.element.classList.remove(this.classes.active)

    const timeline = GSAP.timeline()

    GSAP.to(this.elements.title, {
      opacity: 0,
      duration: 0.6,
      delay: 0,
    })

    GSAP.to(this.elements.stats, {
      opacity: 0,
      duration: 0.8,
      stagger: 0.05,
    })

    if (!Detection.isPhone()) {
      GSAP.to(this.elements.prev, {
        xPercent: -100,
        duration: 0.8,
        opacity: 0,
        ease,
      })
      GSAP.to(this.elements.next, {
        xPercent: 100,
        duration: 0.8,
        opacity: 0,
        ease,
      })
    }

    GSAP.to(this.elements.button, {
      opacity: 0,
      duration: 0.6,
    })

    timeline.to('html', {
      '--project--scaleX': '100%',
      duration: 1,
      ease,
    })

    return super.hide(timeline)
  }

  /**
   * Events.
   */
  onResize() {
    super.onResize()
  }

  onTopClick() {
    this.emit(EVENT_ON_TOP_CLICK)
  }

  /**
   * Listeners
   */
  addEventListeners() {
    this.onTopClickEvent = this.onTopClick.bind(this)

    this.elements.button.addEventListener('click', this.onTopClickEvent)
  }

  removeEventListeners() {
    this.elements.button.removeEventListener('click', this.onTopClickEvent)
  }

  /**
   * Loop.
   */
  update() {
    super.update()
  }

  /**
   * Destroy.
   */
  destroy() {
    this.removeEventListeners()
  }
}
