import Detection from 'classes/Detection'
//import NormalizeWheel from 'normalize-wheel'

import each from 'lodash/each'

// import Canvas from 'components/Canvas'
import Navigation from 'components/Navigation'
import Preloader from 'components/Preloader'
import Transition from 'components/Transition'

// import Details from 'pages/Details'
import Home from 'pages/Home'
import Project from 'pages/Project'

class App {
  constructor() {
    this.template = window.location.pathname.match(/\/project\//)
      ? '/project'
      : window.location.pathname

    this.isChanging = false
    this.currentPath = window.location.pathname

    this.createContent()
    // this.createPreloader()
    this.createTransition()
    this.createNavigation()

    this.addEventListeners()
    this.addLinkListeners()

    this.update()

    // REMOVE THIS AFTER ADDING PRELOADER
    // this.onPreloaded()
  }

  createContent() {
    this.content = document.querySelector('.content')

    this.template = this.content.getAttribute('data-template')
    this.createIndex()
  }

  async createNavigation() {
    this.navigation = new Navigation({
      template: this.template,
      transition: this.transition,
    })

    await this.navigation.show()
    this.createPages()
    //this.onPreloaded()
  }

  createPreloader() {
    this.preloader = new Preloader({
      //   canvas: this.canvas
    })

    this.preloader.once('completed', this.onPreloaded.bind(this))
  }

  createIndex() {
    if (this.content.querySelector('.project')) {
      this.projectIndex = this.content.querySelector('.project').getAttribute('data-index')
    } else {
      this.projectIndex = null
    }
  }

  //   createCanvas () {
  //     this.canvas = new Canvas({
  //       template: this.template
  //     })
  //   }

  createTransition() {
    this.transition = new Transition()
  }

  async createPages() {
    this.home = new Home()
    this.project = new Project()

    this.pages = {
      home: this.home,
      project: this.project,
    }

    this.page = this.pages[this.template]
    this.page.create()
    // await this.navigation.show()
    this.onPreloaded()
    // this.onResize()
    // this.page.show()
  }

  /**
   * Events.
   */
  onPreloaded() {
    this.onResize()

    this.page.show()
    // this.canvas.onPreloaded()

    // this.page.show()
  }

  onPopState() {
    if (this.currentPath !== window.location.pathname) {
      this.currentPath = window.location.pathname

      this.onChange({
        url: window.location.pathname,
        push: false,
      })
    }
  }

  async onChange({ url, push = true }) {
    this.isChanging = true
    this.navigation.reset()
    await this.page.hide()

    const request = await window.fetch(url)

    if (request.status === 200) {
      const responseHtml = await request.text()
      const responseDiv = document.createElement('div')

      if (push) {
        window.history.pushState({}, '', url)
      }

      responseDiv.innerHTML = responseHtml

      const newContent = responseDiv.querySelector('.content')

      this.template = newContent.getAttribute('data-template')

      this.content.setAttribute('data-template', newContent.getAttribute('data-template'))
      this.content.innerHTML = newContent.innerHTML

      this.page = this.pages[this.template]

      this.page.create()
      this.addLinkListeners()
      this.onResize()

      this.createIndex()
      this.navigation.onChange(this.projectIndex)

      await this.page.show()
      this.isChanging = false
    } else {
      console.log('Error Creating page')
    }

    // url = url.replace(window.location.origin, '')
    // console.log(url)

    // const page = this.pages[url]

    // await this.transition.show({
    //   color: COLOR_DARK_GRAY,
    // })

    // if (push) {
    //   window.history.pushState({}, '', url)
    // }

    // this.template = window.location.pathname

    // this.page.hide()

    // this.navigation.onChange(this.template)

    // this.page = page
    // this.page.create()

    // this.page.show()

    // this.onResize()

    // this.transition.hide()
  }

  onResize() {
    if (this.page && this.page.onResize) {
      this.page.onResize()
    }

    // window.requestAnimationFrame(_ => {
    //   if (this.canvas && this.canvas.onResize) {
    //     this.canvas.onResize()
    //   }
    // })
  }

  onTouchDown(event) {
    // if (this.canvas && this.canvas.onTouchDown) {
    //   this.canvas.onTouchDown(event)
    // }

    const isLink = !Detection.isPhone()
      ? event.target.matches('a') || event.target.matches('button')
      : false

    if (this.page && this.page.onTouchDown && !this.navigation.navOpenFlag && !isLink) {
      this.page.onTouchDown(event, this.navigation.navOpenFlag)
    }
  }

  onTouchMove(event) {
    // if (this.canvas && this.canvas.onTouchMove) {
    //   this.canvas.onTouchMove(event)
    // }

    if (this.page && this.page.onTouchDown && !this.navigation.navOpenFlag) {
      this.page.onTouchMove(event)
    }
  }

  onTouchUp(event) {
    // if (this.canvas && this.canvas.onTouchUp) {
    //   this.canvas.onTouchUp(event)
    // }
    if (this.page && this.page.onTouchDown && !this.navigation.navOpenFlag) {
      this.page.onTouchUp(event)
    }
  }

  onWheel(event) {
    // const normalizedWheel = NormalizeWheel(event)

    // if (this.canvas && this.canvas.onWheel) {
    //   this.canvas.onWheel(normalizedWheel)
    // }

    if (this.page && this.page.onWheel && !this.navigation.navOpenFlag) {
      this.page.onWheel(event)
    }
  }

  /**
   * Loop.
   */
  update() {
    if (this.page && this.page.update) {
      this.page.update()
    }

    // if (this.canvas && this.canvas.update) {
    //   this.canvas.update(this.page.scroll)
    // }

    this.frame = window.requestAnimationFrame(this.update.bind(this))
  }

  /***
   * Listeners.
   */
  addEventListeners() {
    window.addEventListener('popstate', this.onPopState.bind(this))
    window.addEventListener('mousewheel', this.onWheel.bind(this))
    window.addEventListener('wheel', this.onWheel.bind(this))

    window.addEventListener('mousedown', this.onTouchDown.bind(this))
    window.addEventListener('mousemove', this.onTouchMove.bind(this))
    window.addEventListener('mouseup', this.onTouchUp.bind(this))

    window.addEventListener('touchstart', this.onTouchDown.bind(this))
    window.addEventListener('touchmove', this.onTouchMove.bind(this))
    window.addEventListener('touchend', this.onTouchUp.bind(this))

    window.addEventListener('resize', this.onResize.bind(this))
  }

  addLinkListeners() {
    const links = document.querySelectorAll('a')

    each(links, (link) => {
      const isLocal = link.href.indexOf(window.location.origin) > -1

      const isNotEmail = link.href.indexOf('mailto') === -1
      const isNotPhone = link.href.indexOf('tel') === -1

      if (isLocal) {
        link.onclick = (event) => {
          event.preventDefault()

          if (this.isChanging || this.currentPath === link.pathname) return
          this.isChanging = true
          this.currentPath = link.pathname

          // Special case for adding hover-state class to project nav arrows for cooler effect
          if (link.getAttribute('data-tag') === 'project-nav') {
            link.classList.add(`${link.className}--active`)
          }

          this.onChange({
            url: link.href,
          })
        }
      } else if (isNotEmail && isNotPhone) {
        link.rel = 'noopener'
        link.target = '_blank'
      }
    })
  }
}

new App()
