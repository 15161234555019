import GSAP from 'gsap'

import Component from 'classes/Component'

import { EVENT_ON_NAV_OPEN, EVENT_ON_NAV_CLOSE } from 'utils/events'
import { CUBIC_STRONG as ease } from 'utils/easings'

export default class Hamburger extends Component {
  constructor({ element, elements }) {
    super({
      element,
      elements,
    })

    this.active = true
    this.openFlag = false
    this.hamTimeline = GSAP.timeline()
  }

  //   createText() {
  //     const text = this.element.textContent

  //     this.elements.wrapper = this.element.querySelector('span')

  //     this.elements.text = document.createElement('div')
  //     this.elements.text.innerHTML = text
  //     this.elements.textSpans = split({
  //       append: false,
  //       element: this.elements.text,
  //       expression: '',
  //     })

  //     this.elements.hover = document.createElement('div')
  //     this.elements.hover.innerHTML = text
  //     this.elements.hoverSpans = split({
  //       append: false,
  //       element: this.elements.hover,
  //       expression: '',
  //     })

  //     this.elements.wrapper.innerHTML = ''
  //     this.elements.wrapper.appendChild(this.elements.text)
  //     this.elements.wrapper.appendChild(this.elements.hover)

  //     GSAP.set(this.elements.hover, {
  //       left: 0,
  //       position: 'absolute',
  //       top: 0,
  //     })

  //     this.timeline = GSAP.timeline({ paused: true })

  //     this.timeline.to(
  //       this.elements.textSpans,
  //       {
  //         duration: 0.5,
  //         ease,
  //         transform: 'rotate3d(1, 0.1, 0, -90deg)',
  //         stagger: 0.01,
  //       },
  //       0,
  //     )

  //     this.timeline.fromTo(
  //       this.elements.hoverSpans,
  //       {
  //         transform: 'rotate3d(1, 0.1, 0, 90deg)',
  //       },
  //       {
  //         duration: 0.5,
  //         ease,
  //         transform: 'rotate3d(0, 0, 0, 90deg)',
  //         stagger: 0.01,
  //       },
  //       0.05,
  //     )
  //   }

  //   createPath() {
  //     this.path = this.element.querySelector('path:last-child')
  //     this.pathLength = this.path.getTotalLength()
  //     this.pathValue = this.pathLength

  //     GSAP.set(this.path, {
  //       strokeDashoffset: this.pathLength,
  //       strokeDasharray: `${this.pathLength} ${this.pathLength}`,
  //     })
  //   }

  onMouseEnter() {
    this.doHamburgerTween()

    GSAP.to(this.elements.closeWrapper, {
      rotation: 90,
      duration: 0.8,
      ease,
    })
  }

  onMouseLeave() {
    this.doHamburgerTween()

    GSAP.to(this.elements.closeWrapper, {
      rotation: 0,
      duration: 0.8,
      ease,
    })
  }

  show() {
    GSAP.to(this.elements.hamburger.childNodes, {
      transformOrigin: 'right center',
      scaleX: 1,
      duration: 0.4,
      stagger: 0.1,
      ease,
    })
  }

  doHamburgerTween() {
    if (this.openFlag || !this.active) return

    const hamTimeline = GSAP.timeline()
    hamTimeline.clear()

    hamTimeline
      .fromTo(
        this.elements.hamburger.childNodes,
        {
          scaleX: 1,
        },
        {
          transformOrigin: 'left center',
          scaleX: 0,
          duration: 0.4,
          stagger: 0.1,
          ease,
        },
      )

      .to(
        this.elements.hamburger.childNodes,
        {
          transformOrigin: 'right center',
          scaleX: 1,
          duration: 0.4,
          stagger: 0.1,
          ease: 'power1.easeOut',
        },
        '-=0.2',
      )
  }

  changeToX() {
    GSAP.killTweensOf(this.elements.hamburger.childNodes)

    const timeline = GSAP.timeline()

    // Hamburger
    timeline
      .to(this.elements.hamburger.childNodes, {
        transformOrigin: 'right center',
        scaleX: 0,
        duration: 0.4,
        stagger: 0.1,
        ease: 'power1.easeOut',
      })

      .to(this.elements.closeIcon.childNodes[0], {
        strokeDashoffset: 0,
        duration: 0.3,
        ease,
      })
      .to(
        this.elements.closeIcon.childNodes[1],
        {
          strokeDashoffset: 40,
          duration: 0.3,
          ease,
        },
        '-=0.2',
      )
  }

  changeToBurger() {
    const timeline = GSAP.timeline()

    timeline
      .to(this.elements.closeIcon.childNodes[0], {
        strokeDashoffset: 20,
        duration: 0.4,
        ease,
      })
      .to(
        this.elements.closeIcon.childNodes[1],
        {
          strokeDashoffset: 20,
          duration: 0.4,
          ease,
        },
        '-=0.2',
      )

    GSAP.to(this.elements.hamburger.childNodes[0], {
      x: 0,
      y: 0,
      scaleX: 1,
      rotation: 0,
      ease,
      duration: 1.25,
      delay: 0.6,
    })
    GSAP.to(this.elements.hamburger.childNodes[1], {
      x: 0,
      y: 0,
      scaleX: 1,
      rotation: 0,
      ease,
      duration: 0.75,
      delay: 0.6,
    })
  }

  onClick() {
    if (!this.active) return

    this.active = false
    this.openFlag = !this.openFlag

    if (this.openFlag) {
      this.emit(EVENT_ON_NAV_OPEN)
      this.changeToX()
    } else {
      this.emit(EVENT_ON_NAV_CLOSE)
      this.changeToBurger()
    }
  }

  reset() {
    this.openFlag = false
    this.active = true
    this.changeToBurger()
  }

  addEventListeners() {
    this.onMouseEnterEvent = this.onMouseEnter.bind(this)
    this.onMouseLeaveEvent = this.onMouseLeave.bind(this)
    this.onClickEvent = this.onClick.bind(this)

    this.element.addEventListener('mouseenter', this.onMouseEnterEvent)
    this.element.addEventListener('mouseleave', this.onMouseLeaveEvent)
    this.element.addEventListener('click', this.onClickEvent)
  }

  removeEventListeners() {
    this.element.removeEventListener('mouseenter', this.onMouseEnterEvent)
    this.element.removeEventListener('mouseleave', this.onMouseLeaveEvent)
    this.element.removeEventListener('click', this.onClickEvent)
  }
}
