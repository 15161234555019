import Prefix from 'prefix'

import { BREAKPOINT_TABLET } from 'utils/breakpoints'
import { getOffset } from 'utils/dom'
import { clamp, map } from 'utils/math'

export default class {
  constructor({ element }) {
    this.transform = Prefix('transform')

    this.element = element
    this.media = element.querySelector('img')
    this.media.onload = (_) => {
      this.onResize()
    }

    this.isVisible = false

    // Randomg range from 20 to 40
    this.PARALLAX_SLIDE_AMOUNT = Math.floor(Math.random() * (40 - 20 + 1) + 20)
    this.PARALLAX_SCALE_AMOUNT = 1.05

    this.parallax = {
      current: -this.amount,
      target: -this.amount,
    }

    this.scale = {
      current: 1.15,
      target: 1.15,
    }

    this.onResize()
  }

  onResize() {
    this.amount = window.innerWidth < BREAKPOINT_TABLET ? 10 : this.PARALLAX_SLIDE_AMOUNT

    this.offset = getOffset(this.element)
  }

  update(scroll) {
    if (!this.offset || this.media.getAttribute('data-active') === '1') {
      return
    }

    const { innerHeight } = window

    const offsetBottom = scroll.current + innerHeight

    if (offsetBottom >= this.offset.top) {
      this.parallax = clamp(
        -this.amount,
        this.amount,
        map(
          this.offset.top - scroll.current,
          -this.offset.height,
          innerHeight,
          this.amount,
          -this.amount,
        ),
      )
      this.scale = clamp(
        1,
        this.PARALLAX_SCALE_AMOUNT,
        map(
          this.offset.top - scroll.current,
          -this.offset.height,
          innerHeight,
          1,
          this.PARALLAX_SCALE_AMOUNT,
        ),
      )

      this.media.style[
        this.transform
      ] = `translate3d(0, ${this.parallax}px, 0) scale(${this.scale})`
    } else {
      this.media.style[
        this.transform
      ] = `translate3d(0, -${this.amount}px, 0) scale(${this.PARALLAX_SCALE_AMOUNT})`
    }
  }
}
